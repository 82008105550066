<script>
import get from 'lodash/get';
import { MuiCheckbox } from '@emobg/motion-ui/v1';

export default {
  components: {
    MuiCheckbox,
  },
  model: {
    prop: 'values',
    event: 'change',
  },
  props: {
    values: {
      type: Array,
      default: () => [],
    },
    index: {
      type: String,
      required: true,
    },
    filters: {
      type: String,
      default: '',
    },
    label: {
      type: Function,
      default: option => option,
    },
    pathValue: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: [],
      selectedOptions: [],
      isReady: false,
    };
  },
  async created() {
    await this.getAlgoliaOptions();
    this.selectedOptions = this.values;
  },
  methods: {
    async getAlgoliaOptions() {
      this.isReady = false;
      const algoliaResponse = await this.$algolia.fetchIndex(this.index, {
        filters: this.filters,
      });

      this.options = get(algoliaResponse, 'hits', []);
      this.isReady = true;
    },
    onModelChange() {
      this.$emit('change', this.selectedOptions);
    },
  },
};
</script>
<template>
  <div class="AlgoliaOptionsComponent">
    <template v-if="isReady">
      <MuiCheckbox
        v-for="option in options"
        :key="option[pathValue]"
        v-model="selectedOptions"
        :label="label(option)"
        :value="option[pathValue]"
        @change="onModelChange"
      />
    </template>
    <ui-skeleton
      v-else
      rows="4"
    />
  </div>
</template>
