var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AlgoliaOptionsComponent" },
    [
      _vm.isReady
        ? _vm._l(_vm.options, function (option) {
            return _c("MuiCheckbox", {
              key: option[_vm.pathValue],
              attrs: { label: _vm.label(option), value: option[_vm.pathValue] },
              on: { change: _vm.onModelChange },
              model: {
                value: _vm.selectedOptions,
                callback: function ($$v) {
                  _vm.selectedOptions = $$v
                },
                expression: "selectedOptions",
              },
            })
          })
        : _c("ui-skeleton", { attrs: { rows: "4" } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }